import React, { Dispatch, SetStateAction, useState } from 'react';
import { useIntl } from 'react-intl';
import Checkbox from '@/common/components/Checkbox/Checkbox';
import { Close } from '@styled-icons/evil/Close';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import {
  Box,
  Flex,
  Select,
  Text,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Image,
  HStack,
  Tooltip,
  Button,
  useToast,
} from '@chakra-ui/react';
import CustomSelect from 'react-select';
import { AU_STATES_ARRAY, RacingType } from '@/lib/Constants';
import { EPlayerType } from '@/lib/DBModels';
import {
  AGE_RANGES,
  RACE_TYPES,
  initialValues,
  paybackPositions,
} from './MoneyBack.config';
import { usePunterCategory } from '@/hooks/usePunterCategory';
import { Form, Formik, FormikProps } from 'formik';
import { TMoneyBackFormikTypes } from './MoneyBack.types';
import { centsToDollars, getIconAssetPath } from '@/common/utils';
import IconSvg from '@/components/IconSvg/IconSvg';
import { groupBy } from 'lodash';
import dayjs from 'dayjs';
import { keys } from '@/api/api.keys';
import { queryMeetRaces, queryRaceMeets } from '@/api/promos/racing/meetRaces';
import { queryVenueMeets } from '@/api/promos/racing/venueMeets';
import { useMutation, useQuery } from 'react-query';
import { TQueryMeetRacesResponse } from '@/api/promos/racing/meetRaces.types';
import { TQueryVenueMeetsResponse } from '@/api/promos/racing/venueMeets.types';
import {
  ContainerHeader,
  RaceCard,
  RaceCardViewWrapper,
  RemoveRaceIcon,
  SectionLabel,
  SectionWrapper,
  selectStyles,
} from './MoneyBack.styles';
import Input from '@/common/components/FormElements/Input';
import validationSchema from './MoneyBack.validations';
import Textarea from '@/common/components/FormElements/Textarea';
import { useMutationPromotion } from '@/api/promos/promo/promotion.hooks';
import {
  AgeRange,
  TMutatePromotionBody,
} from '@/api/promos/promo/promotion.types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  mutateDeletePromotion,
  mutateEditPromotion,
  queryPromotion,
} from '@/api/promos/promo/promotion';
import Confirmation from './components/Confirmation';
import DateTimePicker from '@/common/components/FormElements/DateTimePicker/DateTimePicker';
import { useQueryAffiliates } from '@/api/promoManager/affiliates/affiliates.hooks';
import { TQueryPlatformAffiliateResponse } from '@/api/promoManager/affiliates/affiliates.types';

dayjs.extend(utc);
dayjs.extend(tz);

export default function MoneyBackContainer() {
  const toast = useToast();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const promoId = params.get('id');
  const isInEditMode = !!promoId;
  const { data: affiliates } = useQueryAffiliates();
  const intl = useIntl();

  const [selectedVenue, setSelectedVenue] = useState<{
    venueId: string;
    meet_date: string;
  }>();

  const [confirmation, setConfirmation] = useState<boolean>(false);

  const [storedRaces, setStoredRaces] = useState<TQueryMeetRacesResponse[]>([]);
  const [raceTypes, setRaceType] = useState<RacingType[]>([
    RacingType.GREYHOUNDS,
    RacingType.HARNESS,
    RacingType.HORSE_RACING,
  ]);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const { data: promo, isLoading: isPromoLoading } = useQuery(
    [keys.promotions, promoId],
    () => queryPromotion({ promo_id: promoId ?? '' }),
    {
      enabled: !!promoId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const [minBalance, setMinBalance] = useState<string | undefined>(
    promo?.audience_conditions?.min_balance
      ? (promo?.audience_conditions?.min_balance / 100).toString()
      : undefined
  );
  const [minAVGBetSize, setMinAVGBetSize] = useState<string | undefined>(
    promo?.audience_conditions?.min_avg_bet_size
      ? (promo?.audience_conditions?.min_avg_bet_size).toString()
      : undefined
  );
  const [maxAVGBetSize, setMaxAVGBetSize] = useState<string | undefined>(
    promo?.audience_conditions?.max_avg_bet_size
      ? (promo?.audience_conditions?.max_avg_bet_size).toString()
      : undefined
  );
  const [minEBC, setMinEBC] = useState<string | undefined>(
    promo?.audience_conditions?.min_ebc
      ? (promo?.audience_conditions?.min_ebc / 100).toString()
      : undefined
  );
  const [maxEBC, setMaxEBC] = useState<string | undefined>(
    promo?.audience_conditions?.max_ebc
      ? (promo?.audience_conditions?.max_ebc / 100).toString()
      : undefined
  );
  const [includeNewPunters, setIncludeNewPunters] = useState<
    boolean | undefined
  >(promo?.audience_conditions?.include_new_punters ?? undefined);
  const [lastBetDay, setLastBetDay] = useState<string | number | undefined>(
    promo?.audience_conditions?.max_last_bet_days_ago ?? undefined
  );

  const { data: venues } = useQuery(
    [keys.racingVanueMeets, raceTypes],
    () => queryVenueMeets({ race_types: raceTypes }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const mutation = useMutationPromotion();

  const { mutate: editPromotion } = useMutation(mutateEditPromotion);

  const { isLoading } = useQuery(
    [keys.racingMeetRaces, selectedVenue?.venueId, selectedVenue?.meet_date],
    () =>
      queryMeetRaces({
        venue_id: selectedVenue ? selectedVenue.venueId : '',
        meet_date: selectedVenue ? selectedVenue.meet_date : '',
      }),
    {
      enabled: !!selectedVenue,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => setStoredRaces([...storedRaces, data]),
    }
  );

  const mutateDeletion = useMutation(mutateDeletePromotion);

  const handleDelete = async (promoId: string) => {
    try {
      await mutateDeletion.mutateAsync({ promo_id: promoId }); // Assuming your API expects an object with an id property
      // You can add further logic here, like updating UI or refetching data
      toast({
        title: 'Promotion successfully cancelled!',
        status: 'warning',
        position: 'top-right',
        duration: 5000,
      });
    } catch (error) {
      console.error(error);
      // Handle error, show a message, etc.
    }
  };

  // TODO: Loading states
  console.log(isLoading);

  const handleSetVenue = (id: string, date: string) => {
    setSelectedVenue({ venueId: id, meet_date: date });
  };

  const handleFormSubmit = (
    context: FormikProps<TMoneyBackFormikTypes>,
    type: 'submit' | 'update' | 'delete'
  ) => {
    // Call handleSubmit to handle any form submission
    context.validateForm();
    context.submitForm();

    // Determine the action based on the type prop
    switch (type) {
      case 'submit':
        if (context.errors && Object.keys(context.errors).length === 0) {
          // Check if min_max is an empty string, if so, send it as null
          const valuesToSend = {
            ...context.values,
            audience_conditions: {
              ...context.values.audience_conditions,
              affiliates:
                context.values.audience_conditions?.affiliates || null, // Set to null if undefined
              min_balance: !context.values.audience_conditions?.min_balance
                ? null
                : context.values.audience_conditions?.min_balance,
              max_balance: !context.values.audience_conditions?.max_balance
                ? null
                : context.values.audience_conditions?.max_balance,
              min_avg_bet_size: !context.values.audience_conditions
                ?.min_avg_bet_size
                ? null
                : context.values.audience_conditions?.min_avg_bet_size,
              max_avg_bet_size: !context.values.audience_conditions
                ?.max_avg_bet_size
                ? null
                : context.values.audience_conditions?.max_avg_bet_size,
              min_ebc: !context.values.audience_conditions?.min_ebc
                ? null
                : context.values.audience_conditions?.min_ebc,
              max_ebc: !context.values.audience_conditions?.max_ebc
                ? null
                : context.values.audience_conditions?.max_ebc,
              max_last_bet_days_ago:
                context.values.audience_conditions?.max_last_bet_days_ago ||
                null, // Ensure this property is also handled
              include_new_punters:
                context.values.audience_conditions?.include_new_punters ||
                false, // Set to false if undefined
              min_active_runners:
                context.values.audience_conditions?.min_active_runners || null, // Set to false if undefined
            },
          };

          mutation.mutate(valuesToSend, {
            onSuccess: () => {
              toast({
                title: 'Promotion successfully added!',
                status: 'success',
                position: 'top-right',
                duration: 5000,
              });

              navigate('/promo-manager/money-back');
            },
            onError: ({ response }) => {
              const { user_msg } = response?.data.detail ?? {};

              if (!user_msg) {
                return;
              }

              toast.closeAll();
              toast({
                title: intl.formatMessage({
                  id: 'generic.errorTitle',
                }),
                description: user_msg,
                status: 'error',
                position: 'top',
                duration: 9_000,
              });
            },
          });
        }

        break;
      case 'update':
        toast({
          title: 'Promotion successfully updated!',
          status: 'success',
          position: 'top-right',
          duration: 5000,
        });

        editPromotion({
          name: context.values.name,
          promo_description: context.values.promo_description,
          promo_id: promoId ?? '',
        });

        navigate('/promo-manager/money-back');

        break;
      case 'delete':
        setConfirmation(true);

        break;
      default:
        break;
    }
  };

  if (isPromoLoading) return <></>;

  return (
    <Box pos="relative" h="full">
      <Formik<TMoneyBackFormikTypes>
        initialValues={promo ?? initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);

          console.log(values);
        }}
      >
        {(formikProps) => (
          <>
            <Confirmation
              isOpen={confirmation}
              onClose={() => setConfirmation(!confirmation)}
              onDelete={() => {
                handleDelete(promoId ?? '');

                formikProps.resetForm();

                navigate('/promo-manager/money-back', { replace: true });
              }}
            />
            <FormView
              races={storedRaces}
              venues={venues ?? undefined}
              context={formikProps}
              setVenue={handleSetVenue}
              setRaces={setStoredRaces}
              raceTypes={raceTypes}
              setRaceType={setRaceType}
              minBalance={minBalance}
              setMinBalance={setMinBalance}
              minAVGBetSize={minAVGBetSize}
              setMinAVGBetSize={setMinAVGBetSize}
              maxAVGBetSize={maxAVGBetSize}
              setMaxAVGBetSize={setMaxAVGBetSize}
              minEBC={minEBC}
              setMinEBC={setMinEBC}
              maxEBC={maxEBC}
              setMaxEBC={setMaxEBC}
              lastBetDay={lastBetDay}
              setLastBetDay={setLastBetDay}
              includeNewPunters={includeNewPunters}
              setIncludeNewPunters={setIncludeNewPunters}
              isInEditMode={isInEditMode}
              setIsLoadingSubmit={setIsLoadingSubmit}
              isLoadingSubmit={isLoadingSubmit}
              handleButtonClick={handleFormSubmit}
              affiliates={affiliates}
              promo={promo}
            />
          </>
        )}
      </Formik>
    </Box>
  );
}

type TFormViewProps = {
  races?: TQueryMeetRacesResponse[] | undefined;
  venues: TQueryVenueMeetsResponse | undefined;
  context: FormikProps<TMoneyBackFormikTypes>;
  setVenue: (id: string, date: string) => void;
  setRaces: Dispatch<SetStateAction<TQueryMeetRacesResponse[]>>;
  setRaceType: Dispatch<SetStateAction<RacingType[]>>;
  raceTypes: RacingType[];
  setMinBalance: Dispatch<SetStateAction<string | undefined>>;
  minBalance: string | undefined;
  setMinAVGBetSize: Dispatch<SetStateAction<string | undefined>>;
  minAVGBetSize: string | undefined;
  setMaxAVGBetSize: Dispatch<SetStateAction<string | undefined>>;
  maxAVGBetSize: string | undefined;
  setMinEBC: Dispatch<SetStateAction<string | undefined>>;
  minEBC: string | undefined;
  setMaxEBC: Dispatch<SetStateAction<string | undefined>>;
  maxEBC: string | undefined;
  setLastBetDay: Dispatch<SetStateAction<string | number | undefined>>;
  lastBetDay: string | number | undefined;
  setIncludeNewPunters: Dispatch<SetStateAction<boolean | undefined>>;
  includeNewPunters: boolean | undefined;
  setIsLoadingSubmit: Dispatch<SetStateAction<boolean>>;
  isLoadingSubmit: boolean | undefined;
  isInEditMode: boolean;
  handleButtonClick: (
    context: FormikProps<TMoneyBackFormikTypes>,
    type: 'submit' | 'update' | 'delete'
  ) => void;
  affiliates: TQueryPlatformAffiliateResponse | undefined;
  promo: TMutatePromotionBody | undefined;
};

interface Option {
  value: string;
  label: string;
  id: string;
  date: string;
}

function FormView({
  context,
  races,
  venues,
  setVenue,
  setRaces,
  setRaceType,
  raceTypes,
  setMinBalance,
  minBalance,
  setMinAVGBetSize,
  minAVGBetSize,
  setMaxAVGBetSize,
  maxAVGBetSize,
  setMinEBC,
  minEBC,
  setMaxEBC,
  maxEBC,
  setLastBetDay,
  lastBetDay,
  setIncludeNewPunters,
  includeNewPunters,
  setIsLoadingSubmit,
  isLoadingSubmit,
  isInEditMode,
  handleButtonClick,
  affiliates,
  promo,
}: TFormViewProps) {
  const hasOwn = Object.prototype.hasOwnProperty;
  const { getPlayerIcon, getPunterCategoryColor } = usePunterCategory();
  const groupedVenues = groupBy(venues, (venue) =>
    new Intl.DateTimeFormat('en-AU').format(new Date(venue.meet_date ?? ''))
  );

  const options: { label: string; options: Option[] }[] = Object.entries(
    groupedVenues
  ).flatMap(([date, venues]) => {
    const groupedOptions = venues.map((venue) => ({
      value: `${venue.venue_id}-${venue.meet_date}`,
      label: `${venue.venue_name} - ${venue.race_type}`,
      id: venue.venue_id,
      date: venue.meet_date,
    }));
    return [
      {
        label: dayjs(date, 'DD/MM/YYYY').format('MMMM DD, YYYY'),
        options: groupedOptions,
      },
    ];
  });

  const { data: raceMeets } = useQuery(
    [keys.racingMeetRaces, window.location, context.values],
    () =>
      queryRaceMeets({
        race_ids: context.values.race_ids?.join(',') ?? '',
      }),
    {
      enabled: !!context.values.race_ids && !!isInEditMode,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const timeZone = dayjs.tz.guess();

  // Function to generate the position suffix
  const getPositionSuffix = (number: number): string => {
    const suffixes = ['st', 'nd', 'rd'];
    const index = number > 10 && number < 20 ? 0 : number % 10;
    return number + (suffixes[index - 1] || 'th');
  };

  const localisedKYCStatus = [true, false].map((value) => ({
    value: value.toString(),
    label: value ? 'TRUE' : 'FALSE',
  }));

  return (
    <Form>
      <ContainerHeader mb="3">
        <Box as="span" flex="1" textAlign="left">
          <SectionLabel>Promotion Name</SectionLabel>

          <Input
            name="name"
            sx={{ bg: 'white' }}
            placeholder="Promotion Name"
            defaultValue={context.values.name}
            onChange={(e) => context.setFieldValue('name', e.target.value)}
          />
        </Box>
      </ContainerHeader>
      <SectionWrapper>
        <SectionLabel>Promo description</SectionLabel>

        <Textarea
          placeholder="Promotion description"
          defaultValue={context?.values.promo_description}
          name="promo_description"
          bg="white"
          onChange={(e) =>
            context.setFieldValue('promo_description', e.target.value)
          }
        />
      </SectionWrapper>
      <ContainerHeader>
        <Box as="span" flex="1" textAlign="left">
          Audience
        </Box>
      </ContainerHeader>
      <SectionWrapper>
        <SectionLabel>State of Residence</SectionLabel>

        <Flex gap="2">
          {AU_STATES_ARRAY.map((state: string) => (
            <Checkbox
              key={state}
              isChecked={
                context.values.audience_conditions?.address_locations?.includes(
                  state
                ) ?? false
              }
              isDisabled={isInEditMode}
              value={state}
              name={state}
              error={
                !!context.errors.audience_conditions
                  ? hasOwn.call(
                      context.errors.audience_conditions ?? {},
                      'address_locations'
                    ) &&
                    !!hasOwn.call(
                      context.errors.audience_conditions ?? {},
                      'address_locations'
                    )
                  : false
              }
              onChange={() => {
                const addressStates =
                  context.values.audience_conditions?.address_locations;
                if (addressStates?.includes(state)) {
                  // Remove the state if it exists
                  context?.setFieldValue(
                    'audience_conditions.address_locations',
                    addressStates?.filter((item) => item !== state)
                  );
                } else {
                  // Add the state if it doesn't exist
                  context?.setFieldValue(
                    'audience_conditions.address_locations',
                    [...(addressStates ?? []), state]
                  );
                }
              }}
            />
          ))}
        </Flex>
      </SectionWrapper>
      <SectionWrapper>
        <SectionLabel>Punter Category</SectionLabel>

        <Flex gap="2">
          {Object.values(EPlayerType).map((state: EPlayerType) => (
            <Checkbox
              key={state}
              isDisabled={isInEditMode}
              icon={
                <Box
                  borderRadius="full"
                  p="1"
                  boxSize="5"
                  bg={getPunterCategoryColor(state)}
                >
                  <Image src={getPlayerIcon(state)} />
                </Box>
              }
              error={
                (includeNewPunters &&
                  state === EPlayerType.Newbie &&
                  !context.values.audience_conditions?.punter_categories?.includes(
                    state
                  )) ||
                (!!context.errors.audience_conditions
                  ? hasOwn.call(
                      context.errors.audience_conditions ?? {},
                      'punter_categories'
                    ) &&
                    !!hasOwn.call(
                      context.touched.audience_conditions ?? {},
                      'punter_categories'
                    )
                  : false)
              }
              isChecked={
                context.values.audience_conditions?.punter_categories?.includes(
                  state
                ) ?? false
              }
              value={state}
              name={state}
              onChange={() => {
                const punterCategories =
                  context.values.audience_conditions?.punter_categories;
                if (punterCategories?.includes(state)) {
                  // Remove the state if it exists
                  context?.setFieldValue(
                    'audience_conditions.punter_categories',
                    punterCategories?.filter(
                      (item: EPlayerType) => item !== state
                    )
                  );
                } else {
                  // Add the state if it doesn't exist
                  context?.setFieldValue(
                    'audience_conditions.punter_categories',
                    [...(punterCategories ?? []), state]
                  );
                }
              }}
            />
          ))}
        </Flex>
        {includeNewPunters &&
          !context.values.audience_conditions?.punter_categories?.includes(
            EPlayerType.Newbie
          ) && <Flex color="red">Newbie has to be selected</Flex>}
      </SectionWrapper>

      <SectionWrapper>
        <SectionLabel>AGE</SectionLabel>

        <Flex gap="2">
          {Object.values(AgeRange).map((ageRange: AgeRange) => (
            <Checkbox
              key={ageRange}
              isDisabled={isInEditMode}
              error={
                !!context.errors.audience_conditions
                  ? hasOwn.call(
                      context.errors.audience_conditions ?? {},
                      'age_ranges'
                    )
                  : false
              }
              isChecked={
                context.values.audience_conditions?.age_ranges?.includes(
                  ageRange
                ) ?? false
              }
              value={AGE_RANGES[ageRange]}
              name="audience_conditions.age_ranges"
              onChange={() => {
                const ageRanges =
                  context.values.audience_conditions?.age_ranges;
                if (ageRanges?.includes(ageRange)) {
                  // Remove the state if it exists
                  context?.setFieldValue(
                    'audience_conditions.age_ranges',
                    ageRanges?.filter((item) => item !== ageRange)
                  );
                } else {
                  // Add the state if it doesn't exist
                  context?.setFieldValue('audience_conditions.age_ranges', [
                    ...(ageRanges ?? []),
                    ageRange,
                  ]);
                }
              }}
            />
          ))}
        </Flex>
      </SectionWrapper>

      <SectionWrapper>
        <Flex w="full" gap="3">
          <Flex
            w="50%"
            flexDir="column"
            borderRight="1px solid"
            borderColor="#B1B1B1"
            pr="3"
          >
            <SectionLabel>Min Balance</SectionLabel>
            <Input
              bg="white"
              mb="3"
              name="current-balance-min"
              placeholder="Any"
              max={
                context?.values.audience_conditions?.max_balance ?? undefined
              }
              value={minBalance}
              defaultValue={
                isInEditMode
                  ? centsToDollars(
                      Number(context?.values.audience_conditions?.min_balance)
                    )
                  : undefined
              }
              isDisabled={
                isInEditMode ||
                context.values.audience_conditions?.include_new_punters === true
              }
              onChange={(e) => {
                context?.setFieldValue(
                  'audience_conditions.min_balance',
                  Number(e.target.value) * 100
                );
                setMinBalance(e.target.value);
              }}
            />

            <SectionLabel>Max Balance</SectionLabel>
            <Input
              min={
                context?.values.audience_conditions?.min_balance ?? undefined
              }
              defaultValue={
                isInEditMode
                  ? centsToDollars(
                      Number(context?.values.audience_conditions?.max_balance)
                    )
                  : undefined
              }
              isDisabled={isInEditMode}
              onChange={(e) =>
                context?.setFieldValue(
                  'audience_conditions.max_balance',
                  Number(e.target.value) * 100
                )
              }
              bg="white"
              name="current-balance-max"
              placeholder="Any"
            />
          </Flex>

          <Flex
            w="50%"
            flexDir="column"
            borderRight="1px solid"
            borderColor="#B1B1B1"
            pr="3"
          >
            <SectionLabel>Min AVG BET SIZE</SectionLabel>
            <Input
              isDisabled={
                isInEditMode ||
                context.values.audience_conditions?.include_new_punters === true
              }
              onChange={(e) => {
                context?.setFieldValue(
                  'audience_conditions.min_avg_bet_size',
                  e.target.value
                );
                setMinAVGBetSize(e.target.value);
              }}
              bg="white"
              mb="3"
              name="audience_conditions.min_avg_bet_size"
              placeholder="Any"
              value={minAVGBetSize}
              defaultValue={
                context?.values.audience_conditions?.min_avg_bet_size?.toLocaleString(
                  'en-AU',
                  {
                    style: 'currency',
                    currency: 'AUD',
                  }
                ) ?? ''
              }
              max={
                context?.values.audience_conditions?.max_avg_bet_size ??
                undefined
              }
            />

            <SectionLabel>Max AVG BET SIZE</SectionLabel>
            <Input
              isDisabled={
                isInEditMode ||
                context.values.audience_conditions?.include_new_punters === true
              }
              onChange={(e) => {
                context?.setFieldValue(
                  'audience_conditions.max_avg_bet_size',
                  e.target.value
                );
                setMaxAVGBetSize(e.target.value);
              }}
              bg="white"
              name="audience_conditions.max_avg_bet_size"
              placeholder="Any"
              value={maxAVGBetSize}
              defaultValue={
                context?.values.audience_conditions?.max_avg_bet_size?.toLocaleString(
                  'en-AU',
                  {
                    style: 'currency',
                    currency: 'AUD',
                  }
                ) ?? ''
              }
              min={
                context?.values.audience_conditions?.min_avg_bet_size ??
                undefined
              }
            />
          </Flex>

          <Flex
            w="50%"
            flexDir="column"
            borderRight="1px solid"
            borderColor="#B1B1B1"
            pr="3"
          >
            <SectionLabel>Min EBC</SectionLabel>
            <Input
              isDisabled={
                isInEditMode ||
                context.values.audience_conditions?.include_new_punters === true
              }
              onChange={(e) => {
                context?.setFieldValue(
                  'audience_conditions.min_ebc',
                  e.target.value
                );
                setMinEBC(e.target.value);
              }}
              bg="white"
              mb="3"
              name="audience_conditions.min_ebc"
              placeholder="Any"
              value={minEBC}
              defaultValue={context?.values.audience_conditions?.min_ebc ?? ''}
              max={context?.values.audience_conditions?.max_ebc ?? undefined}
            />

            <SectionLabel>Max EBC</SectionLabel>
            <Input
              isDisabled={
                isInEditMode ||
                context.values.audience_conditions?.include_new_punters === true
              }
              onChange={(e) => {
                context?.setFieldValue(
                  'audience_conditions.max_ebc',
                  e.target.value
                );
                setMaxEBC(e.target.value);
              }}
              bg="white"
              name="audience_conditions.max_ebc"
              placeholder="Any"
              value={maxEBC}
              defaultValue={context?.values.audience_conditions?.max_ebc ?? ''}
              min={context?.values.audience_conditions?.min_ebc ?? undefined}
            />
          </Flex>

          <Flex
            w="50%"
            flexDir="column"
            borderRight="1px solid"
            borderColor="#B1B1B1"
            pr="3"
          >
            <SectionLabel>Affiliates</SectionLabel>
            <CustomSelect
              isMulti
              isDisabled={isInEditMode}
              styles={selectStyles}
              options={affiliates
                ?.filter((a) => a.status === 'active')
                .map((a) => ({
                  value: a.affiliate_name,
                  label: a.affiliate_name,
                }))}
              value={promo?.audience_conditions?.affiliates?.map((a) => ({
                value: a,
                label: a,
              }))}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(selectedOptions: any) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                context?.setFieldValue(
                  'audience_conditions.affiliates',
                  selectedOptions.map(
                    (selectedOption: { option: string; value: string }) =>
                      selectedOption.value
                  )
                );
              }}
            />
            <SectionLabel mt="3.5">Last bet date</SectionLabel>{' '}
            <Select
              isDisabled={
                isInEditMode ||
                context.values.audience_conditions?.include_new_punters === true
              }
              bg="white"
              placeholder="Any"
              size="md"
              value={lastBetDay}
              defaultValue={
                context.values.audience_conditions?.max_last_bet_days_ago ?? ''
              }
              onChange={(e) => {
                context.setFieldValue(
                  'audience_conditions.max_last_bet_days_ago',
                  e.target.value
                );
                setLastBetDay(e.target.value);
              }}
            >
              {Array.from({ length: 30 }, (_, index) => (
                <option key={`option-${index + 1}`} value={index + 1}>
                  {`${index + 1} Day${index !== 0 ? 's' : ''}`}
                </option>
              ))}
            </Select>
          </Flex>

          <Flex w="50%" flexDir="column" borderColor="#B1B1B1">
            <SectionLabel>APPLY TO NEW USERS</SectionLabel>{' '}
            <CustomSelect
              isDisabled={isInEditMode}
              styles={selectStyles}
              options={localisedKYCStatus}
              value={localisedKYCStatus.find(
                (a) =>
                  a.value ===
                  (context.values.audience_conditions?.include_new_punters?.toString() ??
                    'false')
              )}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(e: any) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                context?.setFieldValue(
                  'audience_conditions.include_new_punters',
                  e.value === 'true'
                );
                context?.setFieldValue(
                  'audience_conditions.min_balance',
                  undefined
                );
                context?.setFieldValue(
                  'audience_conditions.min_avg_bet_size',
                  undefined
                );
                context?.setFieldValue(
                  'audience_conditions.max_avg_bet_size',
                  undefined
                );
                context?.setFieldValue(
                  'audience_conditions.min_ebc',
                  undefined
                );
                context?.setFieldValue(
                  'audience_conditions.max_ebc',
                  undefined
                );
                context.setFieldValue(
                  'audience_conditions.max_last_bet_days_ago',
                  undefined
                );
                setIncludeNewPunters(e.value === 'true');
                if (e.value === 'true') {
                  setMinBalance('Any');
                  setMinAVGBetSize('Any');
                  setMinEBC('Any');
                  setMaxAVGBetSize('Any');
                  setMaxEBC('Any');
                  setLastBetDay('Any');
                }
              }}
            />
            <SectionLabel mt="3.5">MIN NUMBER OF RUNNERS</SectionLabel>
            <Input
              isDisabled={isInEditMode}
              type="number"
              onChange={(e) => {
                context?.setFieldValue(
                  'audience_conditions.min_active_runners',
                  e.target.value
                );
              }}
              bg="white"
              name="min_active_runners"
              placeholder="Any"
              defaultValue={
                isInEditMode
                  ? context?.values.audience_conditions?.min_active_runners?.toString()
                  : undefined
              }
            />
          </Flex>
        </Flex>
      </SectionWrapper>

      {/** Promotion Conditions */}

      <ContainerHeader>
        <Box as="span" flex="1" textAlign="left">
          Promotion Conditions
        </Box>
      </ContainerHeader>

      <SectionWrapper>
        <HStack>
          <Flex flexDir="column">
            <SectionLabel>Race Code</SectionLabel>

            <Flex gap="2">
              {RACE_TYPES.map(({ type }) => (
                <Checkbox
                  isDisabled={isInEditMode}
                  key={type}
                  isChecked={raceTypes.includes(type)}
                  icon={
                    <IconSvg
                      sx={{ boxSize: '8', p: '1' }}
                      name={getIconAssetPath('sports', type)}
                    />
                  }
                  value={type}
                  withLabel={false}
                  onChange={() => {
                    if (raceTypes.length === 1 && raceTypes.includes(type)) {
                      // If no raceTypes exist, select all
                      setRaceType(RACE_TYPES.map((race) => race.type));
                    } else if (raceTypes.includes(type)) {
                      // If the type exists, remove it
                      setRaceType(
                        raceTypes.filter((raceType) => raceType !== type)
                      );
                    } else {
                      // If the type doesn't exist, add it
                      setRaceType([...raceTypes, type]);
                    }
                  }}
                />
              ))}
            </Flex>
          </Flex>

          <Flex flexDir="column" w="full">
            <SectionLabel>Eligible Races</SectionLabel>

            <CustomSelect
              isMulti
              isDisabled={isInEditMode}
              styles={selectStyles}
              options={options}
              isClearable={false}
              css={{ border: '1px solid red' }}
              formatOptionLabel={(selectedOption: Option, { context }) =>
                // Customize the label rendering here based on your needs
                context === 'menu' ? (
                  selectedOption.label
                ) : (
                  <div>
                    {selectedOption.label} - {selectedOption.date}
                  </div>
                )
              }
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(selectedOptions: any) => {
                if (selectedOptions) {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  selectedOptions.map((selectedOption: any) => {
                    const selectedVenueId: string = selectedOption.id;
                    const selectedVenueDate: string = selectedOption.date;

                    const selectedVenue = venues?.find(
                      (venue) =>
                        venue.venue_id === selectedVenueId &&
                        venue.meet_date === selectedVenueDate
                    );

                    console.log(selectedOption);
                    if (selectedVenue) {
                      setVenue(selectedVenue.venue_id, selectedVenue.meet_date);
                    }
                    return null;
                  });
                }
              }}
            />
          </Flex>
        </HStack>

        {!!raceMeets?.length && isInEditMode ? (
          <RaceCardView
            context={context}
            raceList={raceMeets}
            setRaces={setRaces}
            isInEditMode={isInEditMode}
          />
        ) : (
          !!races?.length && (
            <RaceCardView
              context={context}
              raceList={races}
              setRaces={setRaces}
              isInEditMode={isInEditMode}
            />
          )
        )}
      </SectionWrapper>

      <SectionWrapper>
        <Flex gap="4">
          <Flex flex="1" flexDir="column">
            <SectionLabel>Pay back up to position</SectionLabel>

            <Flex gap="2">
              {paybackPositions.map((state: number) => (
                <Checkbox
                  isDisabled={isInEditMode}
                  key={state}
                  error={
                    !!context.errors.parameters?.max_payout_position?.length &&
                    !!context.touched.parameters?.max_payout_position
                  }
                  checkboxWrapper={{
                    justifyContent: 'center',
                    height: '50px',
                    display: 'flex',
                    flex: 1,
                  }}
                  isChecked={
                    context.values.parameters.max_payout_position === state
                  }
                  value={getPositionSuffix(state)}
                  name={getPositionSuffix(state)}
                  onChange={() => {
                    context?.setFieldValue(
                      'parameters.max_payout_position',
                      state
                    );
                  }}
                />
              ))}
            </Flex>
          </Flex>
          <Flex flex="1">
            <Flex flexDir="column" w="full">
              <SectionLabel>Percentage</SectionLabel>

              <Flex
                p="3"
                border="1px solid"
                borderColor="#333"
                borderRadius="lg"
              >
                <RangeSlider
                  aria-label={['min', 'max']}
                  step={5}
                  isDisabled={isInEditMode}
                  defaultValue={[
                    0,
                    (context?.values.parameters.percentage ?? 0) * 100,
                  ]}
                  onChange={(e: number[]) =>
                    context.setFieldValue('parameters.percentage', e[1] / 100)
                  }
                >
                  <RangeSliderTrack h="10px" borderRadius="md">
                    <RangeSliderFilledTrack bg="#009CFF" />
                  </RangeSliderTrack>
                  <RangeSliderThumb
                    boxSize="6"
                    bg="#009CFF"
                    border="2px solid"
                    borderColor="white"
                    boxShadow="md"
                    index={1}
                  />
                </RangeSlider>
                <Text ml="8" pl="2">
                  {context?.values.parameters.percentage * 100}%
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex flex="1" flexDir="column">
            <SectionLabel>Maximum Payback Amount</SectionLabel>
            <Input
              InputLeftElement={() => <p>$</p>}
              name="max_amount"
              bg="white"
              placeholder="Enter payback amount"
              size="lg"
              // type="number"
              isDisabled={isInEditMode}
              defaultValue={
                isInEditMode
                  ? centsToDollars(
                      Number(context.values.parameters.max_amount),
                      false
                    )
                  : 0
              }
              onChange={(e) =>
                context.setFieldValue(
                  'parameters.max_amount',
                  Number(e.target.value) * 100
                )
              }
            />
          </Flex>
        </Flex>
      </SectionWrapper>

      <SectionWrapper>
        <Flex w="full" gap="3">
          <Flex w="50%" flexDir="column">
            <SectionLabel color={context.errors.start_time && 'red'}>
              Start Date
            </SectionLabel>

            <DateTimePicker
              disableClock
              name="start_time"
              value={
                context.values.start_time
                  ? dayjs.utc(context.values.start_time).tz(timeZone).format()
                  : undefined
              }
              wrapperProps={{ h: '10' }}
              disabled={isInEditMode}
              onChange={(value) => {
                const utcValue =
                  value === null ? undefined : dayjs(value).utc();
                context?.setFieldValue(`start_time`, utcValue);
              }}
            />
          </Flex>

          <Flex w="50%" flexDir="column">
            <SectionLabel color={context.errors.expiry && 'red'}>
              End Date
            </SectionLabel>

            <DateTimePicker
              disableClock
              name="expiry"
              value={
                context.values.expiry
                  ? dayjs.utc(context.values.expiry).tz(timeZone).format()
                  : undefined
              }
              wrapperProps={{ h: '10' }}
              disabled={isInEditMode}
              onChange={(value) => {
                const utcValue =
                  value === null ? undefined : dayjs(value).utc();
                context?.setFieldValue(`expiry`, utcValue);
              }}
            />
          </Flex>
        </Flex>
      </SectionWrapper>

      <SectionWrapper>
        <Flex justifyContent="end" gap="4">
          {isInEditMode && (
            <Button
              colorScheme="red"
              variant="outline"
              onClick={() => handleButtonClick(context, 'delete')}
            >
              Cancel
            </Button>
          )}

          <Button
            colorScheme="blue"
            isDisabled={!context.dirty || isLoadingSubmit}
            onClick={() => {
              if (!isLoadingSubmit) {
                handleButtonClick(context, isInEditMode ? 'update' : 'submit');
              }
              setIsLoadingSubmit(true);
            }}
          >
            {isInEditMode ? 'Update' : 'Publish'}
          </Button>
        </Flex>
      </SectionWrapper>
    </Form>
  );
}

type TRaceCardViewProps = {
  raceList?: TQueryMeetRacesResponse[] | undefined;
  context: FormikProps<TMoneyBackFormikTypes>;
  setRaces: Dispatch<SetStateAction<TQueryMeetRacesResponse[]>>;
  isInEditMode?: boolean;
};

function RaceCardView({
  raceList,
  context,
  setRaces,
  isInEditMode,
}: TRaceCardViewProps) {
  const groups = groupBy(raceList, ({ meet_date }) =>
    new Intl.DateTimeFormat('en-AU').format(new Date(meet_date ?? ''))
  );

  return (
    <RaceCardViewWrapper>
      {Object.entries(groups).map(([date]) => (
        <Box w="full" key={`${Math.random()}`}>
          <Text color="white" fontSize="md" mb="1" textTransform="uppercase">
            {dayjs(groups[date][0].meet_date).format('MMMM DD, YYYY')}
          </Text>
          {groups[date].map((race) => (
            <RaceCard key={`${Math.random()}`}>
              <Flex alignItems="center">
                <Box sx={{ boxSize: '10', mr: '3' }}>
                  <IconSvg
                    sx={{ boxSize: '10', color: '#194C87' }}
                    name={getIconAssetPath('sports', race?.races[0].race_type)}
                  />
                </Box>
                <Box>
                  <Text fontSize="md" fontWeight="semibold">
                    {race?.venue_name}
                  </Text>
                  <Text fontSize="md" fontWeight="medium">
                    {dayjs(race?.meet_date).format('MMMM DD, YYYY')}
                  </Text>
                </Box>
              </Flex>

              <Flex gap="1">
                {race?.races.map(({ race_id, status, number }) => (
                  <Tooltip
                    hasArrow
                    key={race_id}
                    label={status}
                    isDisabled={status === 'Open'}
                  >
                    <Flex mr="1">
                      <Checkbox
                        isChecked={
                          context.values.race_ids
                            ? context.values.race_ids?.includes(race_id)
                            : false
                        }
                        isDisabled={status !== 'Open'}
                        value={race_id}
                        text={`R${number}`}
                        onChange={() => {
                          let updatedRaceIds: string[];
                          if (
                            context.values.race_ids &&
                            context.values.race_ids.includes(race_id)
                          ) {
                            // If the race_id exists, remove it
                            updatedRaceIds = context.values.race_ids.filter(
                              (id) => id !== race_id
                            );
                          } else {
                            // If the race_id doesn't exist, add it
                            updatedRaceIds = [
                              ...(context.values.race_ids || []),
                              race_id,
                            ];
                          }
                          context?.setFieldValue('race_ids', updatedRaceIds);
                        }}
                      />
                    </Flex>
                  </Tooltip>
                ))}
                {!isInEditMode && (
                  <RemoveRaceIcon
                    as={Close}
                    onClick={() =>
                      setRaces((prevRaces: TQueryMeetRacesResponse[]) => {
                        const raceToRemove = prevRaces.find(
                          (item) => item.venue_id === race.venue_id
                        );
                        const updatedRaces = prevRaces.filter(
                          (item) => item.venue_id !== raceToRemove?.venue_id
                        );
                        const removedRaceIds = raceToRemove
                          ? raceToRemove.races.map((race) => race.race_id)
                          : [];
                        const race_ids = context.values.race_ids;
                        const updatedRaceIds = race_ids?.filter(
                          (race_id) => !removedRaceIds.includes(race_id)
                        );
                        context?.setFieldValue('race_ids', updatedRaceIds);

                        return updatedRaces;
                      })
                    }
                  />
                )}
              </Flex>
            </RaceCard>
          ))}
        </Box>
      ))}
    </RaceCardViewWrapper>
  );
}
